<template>
  <div class="tag">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "TagSmall",
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tag {
  position: relative;
  display: inline-block;
  margin-right: auto;
  height: 40px;
  background-color: $color_grey_3;
  border-radius: 10px;
  margin-right: 12px;

  span {
    display: block;
    padding: 8px 24px;
    font-family: $ObjectSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: rgba(#fff, 0.97);
  }
}
</style>
